export default {
  data() {
    return {
      licensees: [],
      associated_licensees: [],
      borrowers:[],
      borrower_with_licensees: [],
      licensee_id: null,
      borrower_id: null,
      selected_licensee: null,
      selected_borrower: null,
    }
  },
  methods: {
    toggleLicenseeSelection(licensee) {
      this.$http.post(`/api/licensees/${licensee.id}/select`, { selected: !licensee.selected })
        .then(response => {
          this.licensees = this.licensees.map(li => {
            if (li.id === licensee.id) {
              li.selected = !li.selected
              this.showSuccess('Licensee updated')
            }
            return li
          })
        }).catch(err => {
          this.showError('Operation failed')
        })
    },
    getRealestateLicensees() {
      this.$http.get('/api/licensees').then(response => {
        this.licensees = response.data
      }).catch(err => {
        this.showError('Failed to load licensees')
      })
    },
    getBorrowers() {
      this.$http.get('/api/borrowers').then(response => {
        this.borrowers = response.data
      }).catch(err => {
        this.showError('Failed to load licensees')
      })
    },
    getBorrowersWithLicensees() {
      this.$http.get('/api/borrowers/licensees').then(response => {
        this.borrower_with_licensees = response.data
      }).catch(err => {
        this.showError('Failed to load licensees')
      })
    },
    getAssociatedLicensees() {
      this.$http.get('/api/associated-licensees').then(response => {
        this.associated_licensees = response.data
      }).catch(err => {
        this.showError('Failed to load licensees')
      })
    },
    create() {
      this.$http.post('/api/associate', { borrower_id: this.selected_borrower, licensee_id: this.selected_licensee }).then(response => {
        this.$bvModal.hide('show-modal')
        const association = this.borrower_with_licensees.find((b)=>b.id===response.data.id)
        if(!association){
          this.showSuccess('Association created')
          this.borrower_with_licensees = [response.data,...this.borrower_with_licensees]
        }
      }).catch(err => {
        this.showError('Failed to associate borrower with licensee')
      })
    },
    update() {
      this.$http.post('/api/associate', { borrower_id: this.selected_borrower, licensee_id: this.selected_licensee }).then(response => {
        this.showSuccess('Association changed')
        this.borrower_with_licensees = this.borrower_with_licensees.map((b)=>{
          if(b.id===response.data.id){
            return response.data
          }
          return b
        })
      }).catch(err => {
        this.showError('Failed to associate borrower with licensee')
      })
    },
    deleteAssociateLicensees(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/api/licensees/${id}`).then(response => {
            this.showSuccess('Association deleted')
            this.$bvModal.hide('show-modal')
            this.borrower_with_licensees = this.borrower_with_licensees.filter((b)=>b.id !==id)
          }).catch(err => {
            this.showError('Failed to delete association')
          })
        }
      })
    },
  },

}
