<template>
  <b-card class="">
    <div class="mb-1">
      <strong>
        Vendors
      </strong>
    </div>
    <b-table
      small
      :fields="fields"
      :items="vendors"
      responsive="sm"
    >
      <template #cell(action)="data">
        <hwa-button
          :label="data.item.selected?'Vendor Selected':'Select Vendor'"
          :icon="data.item.selected?'CheckIcon':'PlusIcon'"
          :variant="data.item.selected?'flat-success':'flat-warning'"
          @onClick="getRealestateLicensees(data.item)"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BTable, BCard,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import realestateLicensee from '@/mixins/realestateLicensee'

export default {
  components: {
    HwaButton,
    BTable,
    BCard,
  },
  mixins: [showToast,realestateLicensee],
  data() {
    return {
      fields: [{key:'name',label:"Name",sortable:true}, 'action'],
    }
  },
  mounted() {
    this.getRealestateLicensees()
  },
  methods: {

    // getVendors() {
    //   this.$http.get('/api/customer/vendors')
    //     .then(res => {
    //       this.vendors = res.data
    //     })
    //     .catch(err => {
    //       this.showError('Failed to retrieve vendors')
    //     })
    // },
    // selectVendor(vendor) {
    //   this.$http.post(`/api/customer/vendors/${vendor.id}/select`, { selected: !vendor.selected })
    //     .then(res => {
    //       this.vendors = this.vendors.map(v => {
    //         if (v.id === vendor.id) {
    //           // eslint-disable-next-line no-param-reassign
    //           v.selected = !vendor.selected
    //           return v
    //         }
    //         return v
    //       })
    //       this.showSuccess('Successful')
    //     })
    //     .catch(err => {
    //       this.showError('Failed')
    //     })
    // },
  },
}
</script>
